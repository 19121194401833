import React, { useState } from "react";
import SEO from "../../components/seo/seo";
import CustomContainer from "../../components/general/contianer"
import FormButton from "../../components/form-components/form-button"
import FormWrapper from "../../components/form-components/form-wrapper"
import FormInput from "../../components/form-components/form-input-field"
import FormErrorText from "../../components/form-components/form-error-text"
import axios from "axios"
import { BASE_API } from "../../utils/constants"
import { Order } from "../../models/order"
import { useLocation } from "@reach/router"
import OrderDetails from "../../page-components/account-pages/my-account-index/your-orders/order-details"
import { imageKitLink } from "../../utils/fomatters"

const TrackOrder = () => {

    const [orderData, setOrderData] = useState<Order>();
    const location = useLocation();

    const trackOrder = (values: Record<string, any>) => {
        return new Promise((resolve, reject) => {
            axios.post(`${BASE_API}/orders/track`, { orderId: values.orderId, email: values.email })
            .then((response) => {
                setOrderData(new Order(response.data["order_data"]));
            })
            .catch((e) => {
                if (e.response) {
                    const message = e.response.data.message;
                    switch (message) {
                        case "EMAIL_MISMATCH":
                            return reject({email: "The email address doesn't match the order."})
                        case "INVALID_ORDER_ID":
                            return reject({orderId: "The order number doesn't match our records."})
                        default:
                            return reject("We couldn't fetch your order details, please try again later.")
                    }
                } else {
                    console.log(e)
                }
            })
        });
    };

    return (
        <>
            <SEO description={"Manage and track your orders."} title={"Track Order"} keywords={["groomd track order", "track order"]}/>
            <div className={"w-screen"} style={{ minHeight: "calc(100vh - 132px)" }}>
                <div className={`${orderData ? 'opacity-0' : 'opacity-100'} transition duration-300`}>
                    <img alt={"Groomd Products"}
                         draggable={false}
                         src={imageKitLink('/product_images_v3/serum_extra.jpg', { tr: `f-webp,w-1350` })} style={{
                        position: "absolute", width: "100vw", height: "calc(100vh - 112px)", maxHeight: "1100px", top: "112px", zIndex: 0
                    }} className={"hidden md:block left-0 object-cover"}/>
                </div>
                {!orderData && (
                    <div className={"flex items-start md:items-center"} style={{minHeight: "calc(100vh - 132px)"}}>
                        <CustomContainer className={"relative flex justify-center"} style={{ marginTop: "132px" }}>
                            <div className={"max-w-lg w-full md:bg-zinc-100/70 md:border border-zinc-300 md:shadow-xl rounded-2xl md:py-6 md:px-8 md:backdrop-saturate-200 md:backdrop-filter md:backdrop-blur-xl"}>
                                <FormWrapper id={"track_order_form"} onSubmit={trackOrder} className={"space-y-4"} initialValues={{ orderId: location.pathname.split("/").length > 2 ? location.pathname.split("/")[2] : ''}}>
                                    <h1 className={"text-4xl font-display font-extrabold mb-4"}>Look it up with your order number</h1>
                                    <FormInput id={"order_id"} placeholder={"Order Number"} label={"Order Number"} name={"orderId"} required type={"text"}/>
                                    <FormInput id={"order_email"} name={"email"} type={"email"} placeholder={"Enter your email"} label={"Your Email"} required/>
                                    <div>
                                        <FormErrorText/>
                                        <FormButton id={"track_order_button"} style={{ marginTop: "16px" }}>Track Order</FormButton>
                                    </div>
                                </FormWrapper>
                            </div>
                        </CustomContainer>
                    </div>
                )}
                {orderData &&
                <div style={{ marginTop: "132px" }}>
                  <OrderDetails ord={orderData}/>
                </div>
                }
            </div>
        </>
    )

}

export default TrackOrder;